<template>
  <div>
    <PlaceForm title="New Place" ref="FormModal" @payload="onPayload" />
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <div v-if="capability">
      <div>
        <CRow class="justify-content-center mb-4">
          <div>
            <CButtonGroup size="lg">
              <CButton :color="view_type === 'card' ? 'primary':'secondary'" @click="view_type = 'card'"><CIcon name="cil-view-module" /></CButton>
              <CButton :color="view_type === 'list' ? 'primary':'secondary'" @click="view_type = 'list'"><CIcon name="cil-list" /></CButton>
            </CButtonGroup>
          </div>
        </CRow>
        <Search AddBtn @add="onAdd" @search="onSearch">
          <CardList :items="list" enableProgressHeader enableAddingCard v-if="view_type === 'card'" @view="onView" />
          <CRow v-else-if="view_type === 'list'">
            <CCol sm="12">
              <CTableWrapper
                :items="list"
                :fields="FIELDS"
                header-icon="cil-garage"
                :add-btn="false"
                addBtnText="Add"
                :import-btn="false"
                :count="count"
                caption="Place"
                hover
                small
                fixed
                @view="onView"
                @delete="onDelete"
              />
            </CCol>
          </CRow>
          <CRow class="justify-content-end" v-if="pageTotal > 1 && !loading">
            <CPagination
              :activePage.sync="pageCurrent"
              :pages="pageTotal"
              @update:activePage="onUpdatePage"
            >
            </CPagination>
            <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
          </CRow>
        </Search>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { mapState } from 'vuex'
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import CTableWrapper from '@/components/EdxTable';
import CardList from './CardList.vue'
// import PlaceView from './PlaceView.vue'
import PlaceForm from './PlaceForm.vue'

export default {
  name: 'Places',
  components: {
    Search,
    ConfirmModal,
    CardList,
    // PlaceView,
    PlaceForm,
    CTableWrapper
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  data () {
    return {
      loading: false,

      FIELDS: [
        {key: 'name', label: 'Name', _classes: 'align-middle'},
        {key: 'occupancy', label: 'Occupancy'},
        {key: 'free', label: 'Free', _classes: 'align-middle'},
        {key: 'space_cnt', label: 'Total', _classes: 'align-middle'},
        {key: 'overtime', label: 'Overtime', _classes: 'align-middle'},
        {key: 'unlawful', label: 'Unlawful parking', _classes: 'align-middle'},
        {key: 'ops', label: 'Operation', _style: 'width:100px;' }
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      view_type: 'card',

      place: null
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent,
        ipp: 8
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      axios.get(`/api/parking/places/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.list.forEach(el => {
            el.space_cnt = el.spaces.length;
            el.occupied = el.spaces.filter(s => !s.is_free).length;
            el.occupancy = parseInt(el.occupied / el.space_cnt * 100) || 0;
            el.free = el.space_cnt - el.occupied;
            el.overtime = this.$utility.RandomInteger(0, parseInt(el.space_cnt / 20));
            el.unlawful = this.$utility.RandomInteger(0, parseInt(el.space_cnt / 60));
            el.position = [el.lng, el.lat];
            el.notifications = [];
          });
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.geoinfo = result.data.geoinfo;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    onUpdatePage: function(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onSearch: function(text) {
      this.searchText = text;
      this.getList();
    },
    onView: function(id) {
      this.$router.push(`/service/parking/places/${id}`);
    },
    onAdd() {
      this.$refs.FormModal.open({
        place: null,
        title: 'New Place'
      });
    },
    onDelete(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onConfirmed(payload) {
      axios.delete(`/api/parking/places/${payload}/`)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },
    onPayload(payload) {
      axios.post(`/api/parking/places/`, payload)
        .then(response => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
    }
  }
}
</script>
